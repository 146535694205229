import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const userIsLoggedIn = store.getters["auth/getLoginStatus"];

const auth = getAuth();
function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (response) => {
        if (response) {
          response.auth.currentUser
            .getIdTokenResult()
            .then((idTokenResult) => {
              // Confirm the user is an Admin.
              let userIsAdmin = {
                isAdmin: "",
              };

              if (idTokenResult.claims.role == "admin") {
                // Show admin UI.
                userIsAdmin["isAdmin"] = true;
                store.commit("auth/setCustomClaims", userIsAdmin);
              } else {
                // Show regular userIsLoggedIn UI.
                userIsAdmin["isAdmin"] = false;
                store.commit("auth/setCustomClaims", userIsAdmin);
                console.log(
                  "idtoken result: ",
                  idTokenResult.claims.role == "admin"
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        unsubscribe();
        resolve(response);
      },
      reject
    );
  });
}

const routes = [
  {
    path: "/resource/:token",
    name: "sharedPage",
    component: () => import("../views/SharedPage.vue"),
  },

  {
    path: "/profile",
    name: "profile",
    component: () => import("@/components/profileForm"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/walkthroughs",
    name: "walkthroughs",
    component: () => import("@/components/ModelViewerActions/KRPanoViewer.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/",
    name: "home",
    component: () => import("@/layouts/FilesDashboard.vue"),
    meta: {
      requiresAuth: true,
    },

    children: [
      {
        name: "manage-accounts",
        path: "/",
        component: () => import("@/views/Admin/ManageAccounts.vue"),

        children: [],
      },

      {
        path: "/manage-accounts/manage-account-projects/:accountId",
        name: "manage-account-projects",
        component: () =>
          import("@/views/AccountManagement/ManageAccountProjects.vue"),
      },

      {
        path: "/manage-accounts/manage-account-users/:accountId",
        name: "manage-account-users",
        component: () =>
          import("@/views/AccountManagement/ManageAccountUsers.vue"),
      },

      {
        path: "/manage-accounts/manage-platform-users",
        name: "manage-platform-users",
        component: () => import("@/views/Admin/ManageUsers.vue"),
      },

      {
        name: "projectsCDE",
        path: "/:accountId/:projectId/ProjectCDE",

        children: [
          {
            path: "/:accountId/:projectId/ProjectCDE/folders/:folderId",
            name: "CDE-files",
            component: () =>
              import("@/components/folderManagement/DataManagementTable.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/:accountId/:projectId/ProjectCDE/users",
            name: "CDE-Users",
            component: () =>
              import("@/views/AccountManagement/ManageAccountUsers.vue"),

            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/:accountId/:projectId/ProjectCDE/walkthrough",
            name: "CDE-Viewer",
            component: () =>
              import("@/components/ModelViewerActions/KRPanoViewer.vue"),

            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },

  {
    path: "/password_reset",
    name: "resetPassword",
    component: () => import("@/components/ForgotPassword.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),

    beforeEnter: (to, from, next) => {
      if (userIsLoggedIn.loggedIn) {
        return next("/");
      }
      next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the user is authenticated
    if (!(await getCurrentUser())) {
      next("/login"); // Redirect to the login page if not authenticated
    } else {
      console.log(await getCurrentUser());
      next(); // Proceed to the requested route if authenticated
    }
  } else {
    console.log("this is a no auth route", await getCurrentUser());

    next(); // No authentication required, proceed to the requested route
  }
});

export default router;
