import AuthService from "@/services/auth.service";
import router from "@/router";
// import createPersistedState from "vuex-persistedstate";
const user = {};

const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user };

export const auth = {
  namespaced: true,
  state: initialState,
  // plugins: [createPersistedState()],

  actions: {
    async login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("updateFailure");
          console.log(error);
          return Promise.reject(error);
        }
      );
    },

    async googleLogin({ commit }, user) {
      commit("loginSuccess", user);
    },

    async updateProfile({ commit }, userDetails) {
      return AuthService.updateUserProfile(userDetails).then((user) => {
        commit("updateUserSuccess", userDetails);

        router.push("/admin/dashboard");

        return Promise.resolve(user);
      });
    },
    logout({ commit }) {
      const resp = AuthService.logout();
      console.log(resp);
      router.push({ name: "login" });
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },

    setCustomClaims(state, claims) {
      state.customClaims = claims;
    },

    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    updateUserSuccess(state) {
      state.status.loggedIn = true;
      state.user = user;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = true;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
  getters: {
    getCurrentUser(initialState) {
      let user = initialState.user;
      return user;
    },

    getCustomClaims(initialState) {
      return initialState.customClaims;
    },

    getState(initialState) {
      return initialState;
    },
    getLoginStatus(initialState) {
      return initialState.status;
    },
    // checkIfUserIsAdmin(initialState) {
    //   let isUserAdmin;

    //   try {
    //     if (initialState.user.adminStatus.role) {
    //       isUserAdmin =
    //         initialState.user.adminStatus.role === "admin" ? true : false;
    //     } else {
    //       isUserAdmin = false;
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   return isUserAdmin;
    // },
    getRoles(initialState) {
      let roles = initialState.user;
      return roles;
    },
  },
};
