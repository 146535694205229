import { createApp } from "vue";
import App from "./App.vue";

import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";

import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import ToastService from "primevue/toastservice";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);
createApp(App)
  .use(store)
  .component("fa", FontAwesomeIcon)
  .use(router)
  .use(ToastService)
  .use(PrimeVue, {
    theme: {
      pt: {
        panel: {
          header: {
            class: "bg-primary text-primary-contrast",
          },
        },
        checkbox: {
          input: {
            root: "bg-pink-400",
          },
        },
      },
      preset: Aura,
      options: {
        darkModeSelector: "none",
      },
    },
  })
  .mount("#app");
